export class GastosDetalle {
  ID: number;
  Concepto: string;
  Cantidad: number;
  Monto: number;
  Total: number;
  Status: string;
  ID_Gasto: number;

  constructor() {
    this.Cantidad = 0;
    this.Monto = 0;
    this.Concepto = '';
  }
}
