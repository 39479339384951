import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

// Modules
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';

// Directives
import { DatepickerToggleDirective } from '../directives/datepicker-toggle.directive';

// Routes
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProyectoComponent } from './proyecto/proyecto.component';
import { GastoComponent } from './gasto/gasto.component';

@NgModule({
    declarations: [
        DashboardComponent,
        ProyectoComponent,
        GastoComponent,
        DatepickerToggleDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        PagesRoutingModule,
        NgSelectModule,
        NgOptionHighlightModule,
        NgbDatepickerModule
    ],
    exports: [
        DashboardComponent
    ]
})
export class PagesModule {}
