import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { Gasto } from 'src/app/models/Gasto';
import { GastosDetalle } from 'src/app/models/GastosDetalle';
import { GastoService } from 'src/app/services/service.index';
import { AclService } from 'ng2-acl/dist';


@Component({
  selector: 'app-gasto',
  templateUrl: './gasto.component.html',
  styleUrls: ['./gasto.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class GastoComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  lista: any[] = [];
  model: Gasto = new Gasto();
  modeldetalle: GastosDetalle = new GastosDetalle();
  indexdetalle: number;
  detalles: GastosDetalle[] = [];
  proyectos: any[] = [];
  total = 0;

  from: NgbDateStruct;
  to: NgbDateStruct;
  proyecto: any;
  showguardar = true;

  constructor(private _userService: UsersService, private _gastoService: GastoService, private modalService: BsModalService, private toastr: ToastrService, public aclService: AclService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = '';
    let to = '';
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._gastoService.getLista(from, to, this.proyecto).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere guardar Gasto.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
              const model = {
                model: this.model,
                detalles: this.detalles
              };
              this._gastoService.guardar(model)
            .subscribe(
              success => {
                  this.toastr.success('Gasto guardado con exito.', 'Guardado!');
                  this.onBuscar();
                  FormData.resetForm();
                  this.modalRef.hide();
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Gasto();
    this.detalles = [];
    this.showguardar = true;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.config);
    } else {
      this._gastoService.getCompra(id)
    .subscribe(
      data => {
        this.model = data.model;
        this.detalles = data.detalles;
        if (this.model.Fecha) {
          this.model.Fecha = new Date(this.model.Fecha);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.Fecha);
        }

        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._gastoService.getCombos()
      .subscribe(
        data => {
          this.proyectos = data.proyectos;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onShowSolDetalle(model: GastosDetalle, index: number, template: TemplateRef<any>) {
    this.modeldetalle = new GastosDetalle();
    this.indexdetalle = index;
    if (model) {
      this.modeldetalle = model;
    } 
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onDeleteSolDetalle(model: GastosDetalle) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar detalle, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex((Item: GastosDetalle) => Item.ID === model.ID && Item.Concepto === model.Concepto );
        if (Index !== -1) {
            this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: 'Eliminado!',
          text: 'Detalle a sido eliminado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }


  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      this.modeldetalle.Total = this.modeldetalle.Cantidad * this.modeldetalle.Monto;
      if (this.indexdetalle == -1) {
        this.detalles.push(this.modeldetalle);
      } else { 
        this.detalles[this.indexdetalle] = this.modeldetalle;
      }

      this.toastr.success('Concepto agregado con exito.', 'Agregado!');
      this.modaldetalleRef.hide();
    }
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onEliminar(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea eliminar la gasto, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {

        const modelAut = {
          id
        };

        this._gastoService.eliminar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Eliminada!',
              text: 'Gasto eliminar con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onBuscar();
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });

      }
    });
  }

  onGetSubTotal() {
    let subtotal = 0;
    let descuento = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.detalles.length; index++) {
      const element = this.detalles[index];
      subtotal += element.Cantidad * element.Monto;
    }
    this.total = subtotal
    return subtotal;
  }

  OnUpdateTotal() {
    this.modeldetalle.Total = this.modeldetalle.Cantidad * this.modeldetalle.Monto;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }

  onImprimir(id: number) {
    const model = {
      id
    };
    this._gastoService.imprimir(model).subscribe(
        (data: any ) => {
          const fileblob = new Blob([data], {type: 'application/pdf'});
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveOrOpenBlob(fileblob, 'CoverSheet.pdf');
          } else {
              const fileURL = URL.createObjectURL(fileblob);
              window.open(fileURL);
          }
        },
        (error) => {
          this.toastr.error(error.message, 'Error!');
        }
      );
  }

}
