import { Component, OnInit, ViewChild, TemplateRef, ContentChild } from '@angular/core';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class HeaderComponent implements OnInit {

  constructor(private _userService: UsersService) {
    this._userService.loadStorage();
  }

  ngOnInit() {

  }

}
